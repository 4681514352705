<template>
  <div>
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <Asidemenu></Asidemenu>
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header></Header>
          <!--begin::Content-->
          <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Post-->
            <div class="post d-flex flex-column-fluid" id="kt_post">
              <!--begin::Container-->
              <div id="kt_content_container" class="container-xxl">
               <!--begin::Navbar-->
                <div class="card mb-5 mb-xl-10">
                  <div class="card-body pt-9 pb-0">
                    <!--begin::Details-->
                    <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <!--begin::Info-->
                      <div class="flex-grow-1">
                        <!--begin::Stats-->
                        <div class="d-flex flex-wrap flex-stack">
                          <!--begin::Wrapper-->
                          <div class="d-flex flex-column flex-grow-1 pe-8">
                            <!--begin::Stats-->
                            <div class="d-flex flex-wrap">
                              <!--begin::Stat-->
                              <div class="
                                  border border-gray-300 border-dashed
                                  rounded
                                  min-w-125px
                                  py-3
                                  px-4
                                  me-4
                                  mb-3
                                ">
                                <!--begin::Number-->
                                <div class="d-flex align-items-center">
                                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                  <span class="svg-icon svg-icon-3 svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                        transform="rotate(90 13 6)" fill="black" />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <div class="fs-2 fw-bolder w-140px" data-kt-countup="true"
                                    :data-kt-countup-value="supbalance" data-kt-countup-prefix="$"
                                    data-kt-countup-decimal-places="2">
                                    0
                                  </div>
                                </div>
                                <!--end::Number-->
                                <!--begin::Label-->
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{ this.$t('dashboard.supplybalance')}}
                                </div>
                                <!--end::Label-->
                              </div>
                              <div class="
                                  border border-gray-300 border-dashed
                                  rounded
                                  min-w-125px
                                  py-3
                                  px-4
                                  me-4
                                  mb-3
                                ">
                                <!--begin::Number-->
                                <div class="d-flex align-items-center">
                                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                  <span class="svg-icon svg-icon-3 svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                        transform="rotate(90 13 6)" fill="black" />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <div class="fs-2 fw-bolder w-140px" data-kt-countup="true"
                                    :data-kt-countup-value="`${borrowbalanceUSDT}`" data-kt-countup-prefix="$"
                                    data-kt-countup-decimal-places="2">
                                    0
                                  </div>
                                </div>
                                <!--end::Number-->
                                <!--begin::Label-->
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{$t("dashboard.borrowbalance")}}
                                </div>
                                <!--end::Label-->
                              </div>
                              <!--end::Stat-->
                            </div>
                            <!--end::Stats-->
                          </div>
                          <!--end::Wrapper-->
                          <!--begin::Progress-->
                          <div class="
                              d-flex
                              align-items-center
                              w-200px w-sm-300px
                              flex-column
                              mt-3
                            ">
                            <div class="
                                d-flex
                                justify-content-between
                                w-100
                                mt-auto
                                mb-2
                              ">
                              <span class="fw-bold fs-6 text-gray-400">{{this.$t('dashboard.availablecredit')}}</span>
                              <span class="fw-bolder fs-6 fw-bolder" data-kt-countup="true"
                                :data-kt-countup-value="borrowlimit" data-kt-countup-prefix="$"
                                data-kt-countup-decimal-places="2">0</span>
                            </div>
                            <div class="
                                d-flex
                                justify-content-between
                                w-100
                                mt-auto
                                mb-2
                              ">
                              <span class="fw-bold fs-6 text-gray-400">{{$t("dashboard.borrowlimit")}}</span>
                              <span class="fw-bolder fs-6">{{ borrowbalanceyuzde.toFixed(2) }}%</span>
                            </div>
                            <div class="h-5px mx-3 w-100 bg-light mb-3">
                              <div :class="`bg-${isSuccessBorrowUst} rounded h-5px`" role="progressbar"
                                :style="`width: ${borrowbalanceyuzde}%;`" aria-valuenow="50" aria-valuemin="0"
                                aria-valuemax="100"></div>
                            </div>
                          </div>
                          <!--end::Progress-->
                        </div>
                        <!--end::Stats-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Details-->
                  </div>
                </div>
                <!--end::Navbar-->
                <!--begin::Row-->
                <div class="row gy-5 g-xl-8">
                  <!--depositwithdraw tablo start-->
                  <div class="col-xl-12">
                    <!--begin::Tables Widget 5-->
                    <div class="card card-xl-stretch mb-5 mb-xl-10">
                      <div class="card-content">
                        <div class="modal-body py-lg-10 px-lg-10 mt-10">
                          <!--begin::Stepper-->
                          <div class="
                              stepper stepper-pills stepper-column
                              d-flex
                              flex-column flex-xl-row flex-row-fluid
                            ">
                            <!--begin::Aside-->
                            <div class="
                                d-flex
                                justify-content-center justify-content-xl-start
                                flex-row-auto
                                w-100 w-xl-350px
                              ">
                              <!--begin::Nav-->
                              <div class="stepper-nav ps-lg-5">
                                <!--begin::Step 1-->
                                <div class="stepper-item current" data-kt-stepper-element="nav">
                                  <!--begin::Line-->
                                  <div class="stepper-line w-40px"></div>
                                  <!--end::Line-->
                                  <!--begin::Icon-->
                                  <div class="stepper-icon w-40px h-40px">
                                    <i class="stepper-check fas fa-check"></i>
                                    <span class="stepper-number">1</span>
                                  </div>
                                  <!--end::Icon-->
                                  <!--begin::Label-->
                                  <div class="stepper-label w-250px">
                                    <h3 class="stepper-title">{{$t("deposit.swapfrom")}}</h3>
                                    <div class="stepper-desc">
                                      {{$t("deposit.selecttoken")}}
                                    </div>
                                  </div>
                                  <!--end::Label-->
                                </div>
                                <!--end::Step 1-->
                                <!--begin::Step 2-->
                                <div class="stepper-item" data-kt-stepper-element="nav">
                                  <!--begin::Line-->
                                  <div class="stepper-line w-40px"></div>
                                  <!--end::Line-->
                                  <!--begin::Icon-->
                                  <div class="stepper-icon w-40px h-40px">
                                    <i class="stepper-check fas fa-check"></i>
                                    <span class="stepper-number">2</span>
                                  </div>
                                  <!--begin::Icon-->
                                  <!--begin::Label-->
                                  <div class="stepper-label w-250px">
                                    <h3 class="stepper-title">
                                      {{$t("deposit.swapamount")}}
                                    </h3>
                                    <div class="stepper-desc">
                                      {{$t("deposit.wantswap")}}
                                    </div>
                                  </div>
                                  <!--begin::Label-->
                                </div>
                                <!--end::Step 2-->
                                <!--begin::Step 3-->
                                <div class="stepper-item" data-kt-stepper-element="nav">
                                  <!--begin::Line-->
                                  <div class="stepper-line w-40px"></div>
                                  <!--end::Line-->
                                  <!--begin::Icon-->
                                  <div class="stepper-icon w-40px h-40px">
                                    <i class="stepper-check fas fa-check"></i>
                                    <span class="stepper-number">3</span>
                                  </div>
                                  <!--end::Icon-->
                                  <!--begin::Label-->
                                  <div class="stepper-label w-250px">
                                    <h3 class="stepper-title">{{$t("deposit.swapto")}}</h3>
                                    <div class="stepper-desc">
                                      {{$t("deposit.selectbuytoken")}}
                                    </div>
                                  </div>
                                  <!--end::Label-->
                                </div>
                                <!--end::Step 3-->
                                <!--begin::Step 4-->
                                <div class="stepper-item" data-kt-stepper-element="nav">
                                  <!--begin::Line-->
                                  <div class="stepper-line w-40px"></div>
                                  <!--end::Line-->
                                  <!--begin::Icon-->
                                  <div class="stepper-icon w-40px h-40px">
                                    <i class="stepper-check fas fa-check"></i>
                                    <span class="stepper-number">4</span>
                                  </div>
                                  <!--end::Icon-->
                                  <!--begin::Label-->
                                  <div class="stepper-label w-250px">
                                    <h3 class="stepper-title">{{$t("deposit.swapfinal")}}</h3>
                                    <div class="stepper-desc">
                                      {{$t("deposit.completetransaction")}}
                                    </div>
                                  </div>
                                  <!--end::Label-->
                                </div>
                                <!--end::Step 4-->
                                <!--begin::Step 5-->
                                <div class="stepper-item" data-kt-stepper-element="nav">
                                  <!--begin::Line-->
                                  <div class="stepper-line w-40px"></div>
                                  <!--end::Line-->
                                  <!--begin::Icon-->
                                  <div class="stepper-icon w-40px h-40px bg-danger">
                                    <i class="stepper-check fas fa-check"></i>
                                    <span class="stepper-number" style="color: #ffffff !important">5</span>
                                  </div>
                                  <!--end::Icon-->
                                  <!--begin::Label-->
                                  <div class="stepper-label w-250px">
                                    <h3 class="stepper-title">{{$t("deposit.warning")}}</h3>
                                    <div class="stepper-desc">
                                      {{$t("deposit.warningslippage")}}
                                    </div>
                                  </div>
                                  <!--end::Label-->
                                </div>
                                <!--end::Step 5-->
                              </div>
                              <!--end::Nav-->
                            </div>
                            <!--begin::Aside-->
                            <!--begin::Content-->
                            <div class="flex-row-fluid py-lg-5 ms-15">
                              <!--begin::Form-->
                              <form class="form" novalidate="novalidate" autocomplete="off">
                                <!--begin::Step 1-->
                                <div class="current" data-kt-stepper-element="content">
                                  <div class="w-100">
                                    <!--begin::Input group-->
                                    <div class="fv-row mb-10">
                                      <!--begin::Label-->
                                      <label>{{$t("deposit.swapfrom")}}</label>
                                      <label class="
                                          d-flex
                                          align-items-center
                                          fs-5
                                          fw-bold
                                          mb-2
                                        ">
                                        <span class="form-floating w-125px me-2">
                                          <select class="form-select pt-0 pb-0" v-model="selectWalletFrom"
                                            @change="fromAmount=''; toAmount=''; selectenWallet()">
                                            <option v-for="swallet in walletall" :value="swallet"
                                              :key="swallet.index">{{swallet}}</option>
                                          </select>
                                        </span>
                                        <span class="form-floating w-85">
                                          <input type="text" class="form-control" id="floatingInput"
                                            v-model="fromAmount" placeholder="0.00000000"
                                            v-on:keyup="selectWalletTo='';" />
                                          <label for="floatingInput" class="fs-7">{{$t("deposit.amount")}}</label>
                                        </span>
                                      </label>
                                      <label class="text-muted" v-if="selectWalletFrom"
                                        @click="fromAmount=walletList[selectWalletFrom]['userbalance']; selectWalletTo=''">{{walletList[selectWalletFrom]["userbalance"]}}
                                        {{$t("deposit.availabletoswap")}}</label>
                                      <!--end::Input-->
                                    </div>
                                    <div class="separator my-10"></div>
                                    <div class="fv-row mb-10">
                                      <!--begin::Label-->
                                      <label>{{$t("deposit.swapto")}}</label>
                                      <label class="
                                          d-flex
                                          align-items-center
                                          fs-5
                                          fw-bold
                                          mb-2
                                        ">
                                        <span class="form-floating w-125px me-2">
                                          <select class="form-select pt-0 pb-0" :disabled="!fromAmount"
                                            v-model="selectWalletTo" v-on:change="getWalletAverage(fromAmount)">
                                            <option v-for="swallet2 in walletall2" :value="swallet2"
                                              :key="swallet2.index">{{swallet2}}</option>
                                          </select>
                                        </span>
                                        <span class="form-floating w-85">
                                          <input disabled type="text" class="form-control pt-0 pb-0 text-muted"
                                            placeholder="0.00000000" v-model="toAmount" />
                                        </span>
                                      </label>
                                      <label class="text-muted w-50">{{$t("deposit.slippagetolerance")}}</label>
                                      <label class="text-muted w-50 text-end">{{$t("deposit.commission")}} : 0%</label>
                                      <!--end::Input-->
                                    </div>
                                    <div class="fv-row mb-10">
                                      <button v-if="buttonDisabled===0" type="button" class="btn btn-lg btn-primary m-5 w-90" @click="createSwap()">
                                        {{$t("deposit.swap")}}
                                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1"
                                              transform="rotate(-180 18 13)" fill="black" />
                                            <path
                                              d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                              fill="black" />
                                          </svg>
                                        </span>
                                      </button>
                                      <button v-if="buttonDisabled===1" type="button" class="btn btn-lg btn-primary m-5 w-90" >
                                        <span class="spinner-border spinner-border-sm"></span>
                                        {{$t("deposit.loading")}}
                                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1"
                                              transform="rotate(-180 18 13)" fill="black" />
                                            <path
                                              d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                              fill="black" />
                                          </svg>
                                        </span>
                                      </button>
                                      
                                      <div v-if="selectWalletTo" class="d-flex
                                      justify-content-center">
                                      <div class="border border-gray-300 border-dashed rounded-circle w-50px py-3 mb-3 text-center">
                                          <div class="fs-2 fw-bolder">{{geriSayim}}</div>
                                      </div>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                                <!--end::Step 1-->
                              </form>
                              <!--end::Form-->
                            </div>
                            <!--end::Content-->
                          </div>
                          <!--end::Stepper-->
                        </div>
                      </div>
                      <!--end::Modal content-->
                    </div>
                    <!--end::Tables Widget 5-->
                  </div>
                  <!--depositwithdraw tablo end-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Container-->
            </div>
            <!--end::Post-->
          </div>
          <!--end::Content-->
          <Footer></Footer>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
    <!--end::Root-->
    <!--begin::Drawers-->
    <!--begin::Scrolltop-->
    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
      <span class="svg-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
          <path
            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
            fill="black" />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>
    <!--end::Scrolltop-->
    <!--end::Main-->
  </div>
</template>
<script>
  import DataService from "../services/DataService";
  import Asidemenu from "./Sidebar";
  import Header from "./shares/Header";
  import Footer from "./shares/Footer";
  export default {
    name: "Swap",
    metaInfo: function() {
      return {
        title: this.$t("metatag.titledefault"),
        titleTemplate: "%s | "+this.$t("metatag.titleDetailSwap"),
        meta: [
        { name: 'description', content:  this.$t("metatag.description") },
        { name: 'keywords', content:  this.$t("metatag.keywords") }
        ]
      }
    },
    components: {
      Asidemenu,
      Header,
      Footer
    },
    data() {
      return {
        walletall: [],
        walletall2: [],
        selectWalletFrom: "",
        selectWalletTo: "",
        walletList: {},
        supbalance: 0,
        borrowlimit: 0,
        borrowbalanceUSDT: 0,
        userControl: this.$session.get("logtoken") ? 1 : 0,
        fromAmount: "",
        toAmount: "",
        geriSayim: 0,
        buttonDisabled:0
      };
    },
    methods: {
      sessionKontrol() {
        if (!this.$session.get("logtoken")) {
          window.location.href = `/${this.$session.get("lang")}/login`;
        }
      },
      userActivity(){
          DataService.userActivity()
          .then(response => {  
          this.term = response.data.ip
          })
          this.browser = navigator.userAgent
          this.browserLang = navigator.language
          this.platform = navigator.platform        
      },
      async createToken(
          data0 = "null",
          data1 = "null",
          data2 = "null",
          data3 = "null",
          data4 = "null",
          data5 = "null",
          data6 = "null",
          data7 = "null",
          data8 = "null",
          data9 = "null",
          data10 = "null"
        ) {
          var send = [];
            await DataService.veriSifrele([
            data0,
            data1,
            data2,
            data3,
            data4,
            data5,
            data6,
            data7,
            data8,
            data9,
            data10,
          ]).then(response => {
            send.push(response.data)
          });
          return send;
        },
      allWallets() {
        if (this.userControl != 1) return;
        if (this.sayfaDurum == 0) {
          return;
        }
        this.supbalance = 0;
        this.borrowlimit = 0;
        this.borrowbalanceUSDT = 0;
        this.walletall = []
        DataService.getUserWallets({
          token: this.$session.get("logtoken"),
        }).then((response2) => {
          DataService.getAllWallets().then((response) => {
            for (let index = 0; index < response.data.length; index++) {
              const element = response.data[index];
              if(element.wallet_ex_status===1){
                this.walletall.push(element.wallet_short);
              }
              if (element) this.walletList[element.wallet_short] = element;
              this.userWallet(
                element.wallet_short,
                response2.data[element.wallet_short]
              );
            }
          });
        });
      },
      userWallet(gelen, userWallet) {
        if (userWallet) {
          this.walletList[gelen]["userbalance"] = userWallet.wallet_user_balance;
          if (userWallet.wallet_user_earn === 1) {
            this.supbalance =
              this.supbalance +
              userWallet.wallet_user_balance *
              this.walletList[gelen]["wallet_price"];
          }
          if (userWallet.wallet_user_coll === 1) {
            this.borrowlimit =
              this.borrowlimit +
              userWallet.wallet_user_balance *
              this.walletList[gelen]["wallet_price"] *
              ("0." + this.walletList[gelen].wallet_col_factor);
          }

          if (userWallet.wallet_user_borrow_balance) {
            this.borrowbalanceUSDT =
              this.borrowbalanceUSDT +
              userWallet.wallet_user_borrow_balance *
              this.walletList[gelen]["wallet_price"];
          }
        }
      },
      selectenWallet(){
        this.walletall2 = []
        for (let index = 0; index < this.walletall.length; index++) {
            const element = this.walletall[index];
            if(this.selectWalletFrom!=element){
              this.walletall2.push(element);
            }
          }
      },
      countDown() {
        setTimeout(() => {

          if (this.geriSayim == 0) {
            this.geriSayim = 15;
          }
          this.geriSayim = this.geriSayim - 1;
          this.countDown();
          this.getWalletAverage();
        }, 1000);
      },
      getWalletAverage() {
        if (this.userControl != 1) return;
        if (this.selectWalletFrom && this.selectWalletTo && this.fromAmount) {
          const fromAmountUsdt = (this.walletList[this.selectWalletFrom].wallet_price * this.fromAmount) * 0.996;
          const toAmountUsdt = (fromAmountUsdt / this.walletList[this.selectWalletTo].wallet_price) * 0.996;
          this.toAmount = toAmountUsdt;
        }
        /**/
      },
      async createSwap(){
        const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
        if(!this.selectWalletFrom || !this.selectWalletTo || !this.fromAmount || !this.toAmount){
          this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
          this.$vToastify.warning("",this.$t("message.formerror")); return;
        }
        this.buttonDisabled=1;
        DataService.swapCrate({
          token: this.$session.get("logtoken"),
          dataToken:await this.createToken(
                    this.selectWalletFrom,
                    this.selectWalletTo,
                    this.fromAmount,
                    this.toAmount,
                    this.walletList[this.selectWalletTo].wallet_amount_dec,
                    this.walletList[this.selectWalletFrom].wallet_amount_dec
                    ),
          activity: await this.createToken(
                          this.term,
                          this.browser,
                          this.browserLang,
                          this.platform
                    )
        }).then((response) => {
          if (!response.data.error && response.data.status == "ok") {
          console.log(response.data)
          this.buttonDisabled=0;
          this.allWallets();
          this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
          this.$vToastify.success("",this.$t("message." + response.data.success));
          }else{
            this.buttonDisabled=0;
            this.$vToastify.setSettings({position: "bottom-right",theme: "light",});
            this.$vToastify.warning("",this.$t("message."+ response.data.error)); return;
          }
        });
      }
    },
    computed: {
      borrowbalanceyuzde: function () {
        let kontrol = "";
        if ((this.borrowbalanceUSDT / this.borrowlimit) * 100) {
          kontrol = (this.borrowbalanceUSDT / this.borrowlimit) * 100;
        } else {
          kontrol = 0;
        }
        return kontrol;
      },
      isSuccessBorrowUst: function () {
        let kontrol = "";
        if (this.borrowbalanceyuzde <= 50) {
          kontrol = "success";
        } else if (this.borrowbalanceyuzde > 50 && this.borrowbalanceyuzde < 80) {
          kontrol = "warning";
        } else if (this.borrowbalanceyuzde > 80) {
          kontrol = "danger";
        }
        return kontrol;
      },
    },
    mounted() {
      this.sessionKontrol();
    },
    created() {
      this.userActivity()
      this.allWallets();
      this.countDown();
      setInterval(() => { this.allWallets(); }, 15000);
      const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
    },
  };
</script>